import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const MarkdownFile = (props: {
  path: string,
}) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    import(`markdown/${props.path}`)
      .then(res => {
        fetch(res.default)
          .then(res => res.text())
          .then(res => setContent(res))
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  }, [props.path]);

  if (!content) {
    return <div />
  }

  return (
    <ReactMarkdown plugins={[gfm]} rehypePlugins={[rehypeRaw]}>
      {content}
    </ReactMarkdown >
  );
};

export default MarkdownFile;
