import { Button, Container, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { ContentPadding } from 'theme';

const CenterAligned = styled.div`
  text-align: center;
  width: 100%;
`;

const Spacer = styled.div`
  height: 5vh;
`;

const LandingPage = () => {
  return (
    <ContentPadding>
      <Container maxWidth="sm" component="main">
        <Spacer />
        <Spacer />
        <Typography component="h2" variant="h3" align="center" color="textPrimary" gutterBottom>
          Paranoia
        </Typography>
        <Spacer />
        <Spacer />
        <Typography component="p" variant="body1" align="center" color="textSecondary" >
          Get answers to questions that your friends would rather keep secret.
        </Typography>
        <Spacer />
        {/* <Spacer />
      <CenterAligned>
        <Button variant="contained" color="primary">
          Play Game
        </Button>
      </CenterAligned>
      <Spacer /> */}
        <Spacer />
        <CenterAligned>
          <Button variant="text" color="primary" href="/terms">
            Terms
          </Button>
          <Button variant="text" color="primary" href="/privacy">
            Privacy Policy
          </Button>
          <Button variant="text" color="primary" href="/support">
            Support
          </Button>
        </CenterAligned>
        <Spacer />
      </Container>
    </ContentPadding>
  );
};


export default LandingPage;
