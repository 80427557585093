import { CssBaseline, Link, ThemeProvider, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPage from 'page/landing';
import PrivacyPage from 'page/privacy';
import TermsPage from 'page/terms';
import theme from 'theme';
import styled from 'styled-components';
import SupportPage from 'page/support';

const CopyrightPadding = styled.div`
  padding: 30px;
`;

function Copyright() {
  return (
    <CopyrightPadding>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://paranoia.josiahsaunders.com/">
          Paranoia
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    </CopyrightPadding>
  );
}

function Routes() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/privacy" component={PrivacyPage} />
          <Route exact path="/support" component={SupportPage} />
          <Route exact path="/terms" component={TermsPage} />
        </Switch>
      </div>
    </Router>
  );
}

class App extends React.Component {
  public render(): ReactNode {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
        <Copyright />
      </ThemeProvider>
    );
  }
}

export default App;
