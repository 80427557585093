import MarkdownFile from 'widget/markdown-file';
import React from 'react';
import { ContentPadding } from 'theme';

const TermsPage = () => {
  return (
    <ContentPadding>
      <MarkdownFile path={'TERMS.md'} />
    </ContentPadding>
  );
};

export default TermsPage;
